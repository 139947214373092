export enum MODULES {
  // Modulos usados con ids de back.
  AGENTS = '3927c45f-ce9c-48cb-8137-95e565a920d6',
  DATA_TYPES = 'd4415a43-8440-4fd1-b670-c621ebbd18af',
  PRODUCTS = 'ce4de0c2-145e-4db2-aabb-569e4ef45d73',
  POLYGONS = 'd88cd97e-2523-45f3-8715-286c0bae5a7b',

  // Modulos sin usar
  FLOWS = '0b155334-3f2f-425e-84f9-569a336ab021',
  CHANNELS = '323cfc55-1b09-49cc-abd9-6b9f60c8bc55',
  ROLES = '1b857d51-e14b-41ef-861a-673fd16fd743',
  SUBJECTS = 'f9153ef9-18cf-4d46-90e3-b0deda722a42'
}

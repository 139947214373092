import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { catchError, debounce, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { forkJoin, from, interval, Observable, of, Subject, throwError, timer,race } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ElementsForm, InstanceContainer } from '../../../shared/models';
import { FormElement } from '../../../shared/enums';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../../shared/services/global.service';
import { FormDataService } from '../../../shared/services/form-data.service';
import { OriginationsService } from '../../../shared/services/originations.service';
import { AlertService } from '../../../shared/services/alerts.service';
import { ProfileService } from '../../../shared/services/profile.service';
import { FormIgnoredElements } from '../enums/form-ignored-elements.enum';
import { FormFileUploaderSharedService } from '../../../shared/services/form-file-uploader-shared.service';

export let DELAY = 1500; //This delay only must used to show loading spinner
declare var solicitud;

const DEBOUNCE = 6000; //Used to wait 8 seconds befire trigger the partial save

@Injectable()
export class FormStorageService implements OnDestroy {
  pendingRequest = false;
  actualFormGroup: FormGroup;
  stopListeningForm$ = new Subject<boolean>();
  denyPartialSave = true;
  private _status$ = new Subject<any>();
  private _backup = {};
  private _avoidPartialSaves = false;

  private $emitedLostFocus = new Subject<number>(); //Subject to receive event when some control lost focus

  private pendingDebounce = false; //Variable to control when form is waiting inbounce to emit new request

  constructor(private _storage: LocalStorage, private _formData: FormDataService, private _globalSvc: GlobalService,
              private _origination: OriginationsService, private _dialog: MatDialog, private _alert: AlertService,
              private readonly _profile: ProfileService, private readonly _formFileUploadSharedSrv: FormFileUploaderSharedService) {
    this.startProcesses();
    this._profile.userProfile$.asObservable().pipe(filter(userInfo => !!userInfo)).subscribe(userInfo => {
      this.denyPartialSave = userInfo.originations.denyPartialSave;
      DELAY = this.denyPartialSave ? 500 : DELAY;
    });
  }

  ngOnDestroy(): void {
    const localStorage$ = this.clearContainerLocalStorage();
    if (localStorage$) localStorage$.subscribe();
    this._status$.next(true);
    this._status$.complete();
  }

  /**
   * Función que verifica en local storage si hay información sin guardar, en caso de haber, la guarda y despues limpia.
   * @param idContainer Id del contenedor (opcional). Se toma el contenedor actual por default.
   * @param callback Callback (opcional) a invocar.
   */
  checkForLocalStorage(idContainer?: string, callback?: Function): void {

    this._backup = {};
    this._formData.isPollingForm = true;
    const actualContainerId = this._formData.getActualContainerId(); //If actualContainerId == undefined meanning that actual form is not loaded yet
    const container = idContainer || actualContainerId;
    if (!container) return;
    this._storage.getItem(container).pipe(
      filter(data => {

        if ((!data || !actualContainerId) && callback){ //If data is not cached or form is not loaded

          callback(false);
        } 
        return !!data;
      }),
      switchMap(data =>
        forkJoin([this.partialSave(data, container), this.clearContainerLocalStorage(container)]).pipe(tap(() => {

            if (callback) callback(true);
          }
        )))).subscribe({
      next: () => this._alert.success('Tus datos fuerón guardados con éxito.')
    })
    ;
  }

  /**
   * Función que valida la data del elemento contra su tipo de dato definido.
   * @param control Control del FormGroup del formulario.
   * @param element Elemento proveniente de la datafront.
   * @param data Lo que se va a mandar a la función definida en su tipo de dato.
   */
  esElementoConDatatypeValido(control: FormControl, element: ElementsForm, data: any = control.value): boolean {
    let err;
    const keyNameDictionary = this._formData.getKeyNamesByFullNameMap();
    if (keyNameDictionary.has(element.fullName)) {
      const dataTypesDictionary = this._formData.getDataTypesMap();
      const dataTypeName = keyNameDictionary.get(element.fullName);
      if (dataTypesDictionary.has(dataTypeName)) {
        const dataTypeObj = dataTypesDictionary.get(dataTypeName) as any;
        const validators = dataTypeObj.validators;

        // Need to check if element has errorText property because it is from validation in fieldFunctions and is custom
        if(element.errorText !== null && element.errorText !== undefined && element.errorText !== ''){

          err = { isCustom: element.errorText };
        }

        const updateCustomError = () => {
          const erroresPrevios = control.errors;
          if (erroresPrevios) {
            control.setErrors({ ...erroresPrevios, ...err });
          } else {
            control.setErrors(err);
          }
        };
        updateCustomError();
        if (validators || validators.length) {
          validators.forEach(exp => {
            const _v_ = element.elementType === FormElement.DATE_PICKER_INLINE && !!data ? moment(data).format('DD/MM/YYYY') : data;
            const func = eval(exp.expression);
            if (!func) {
              err = { isCustom: exp.message || 'Error desconocido' };
              updateCustomError();
            }
          });
        }
        const numErrors = control.errors && Object.keys(control.errors).length || 0;
        return !numErrors || numErrors === 1 && control.errors.required;
      }
    } else {
      console.warn(`El fullName ${ element.fullName } no cuenta con DATATYPE.`);
    }
    return true;
  }

  manualSaveForm(): Observable<any> {
    this.validateForm(this._formData.getContainerFormGroup());
    const allValues = this.transformAllContainer(this._formData.getContainerFormGroup());
    return this.partialSave(allValues, undefined, true);
  }

  manualSaveFormByFullName(fullName: string, ignoreValidation = false): Observable<any> {
    const element = this._formData.getDatafrontElement(fullName);
    if (element) {
      const value = this.transFormFullName(element, ignoreValidation);
      return this.partialSave(value, undefined, true);
    }
  }

  /**
   * Función que comienza el proceso de escucha, filtrado, transformación y guardado de la información.
   */
  private startProcesses(): void {
    this._globalSvc.getContainer().pipe(
      filter((data: InstanceContainer) => data && !!data.containerId),
      tap(() => this.actualFormGroup = null),
      tap(() => this._status$.next(true)),
    ).subscribe(() => {
      interval().pipe(
        takeWhile(() => !this.actualFormGroup),
        map(() => this._formData.getContainerFormGroup()),
        filter(formFound => !!formFound)
      ).subscribe({
        next: formFound => {
          this.actualFormGroup = formFound;
          this.checkForLocalStorage();
          this.startListening(formFound);
          this.clearContainerLocalStorage().subscribe();
        }
      });
    }, err => console.log(err), () => console.log('Se terminó la escucha...'));
  }

  /**
   * Función que busca y borra el registro del local storage con el id de un contenedor.
   * @param idContainer Id del contenedor a borrar.
   */
  private clearContainerLocalStorage(idContainer?: string): Observable<any> {
    const container = idContainer || this._formData.getActualContainerId() || this._formData.lastProcessedContainerId;
    if (!container) return;
    return this._storage.removeItem(container);
  }

  /**
   * Función que escucha cambios del formulario.
   * @param form Formulario a escuchar.
   */
  private startListening(form: FormGroup): void {
    solicitud.formulario = form.controls;
    form.valueChanges.pipe(
      takeUntil(this.stopListeningForm$),
      distinctUntilChanged(),
      tap(() => !this._formData.isPollingForm && (this.pendingRequest = true)),
      filter(() => {
        const res = !this._formData.isPollingForm && !!this._formData.affectedFullNames.size;
        if (!res) this.pendingRequest = false;
        return res;
      }),
      map(this.transformForm.bind(this)),
      filter(data => {
        this.pendingRequest = !this.denyPartialSave && !!Object.getOwnPropertyNames(data).length;
        if (this.denyPartialSave) {
          // this._deletePendingFiles();
          this._uploadPendingFiles();
        }
        this._formData.isSaving.next(this.pendingRequest);
        return this.pendingRequest;
      }),
      debounce(()=>{ //Wait for debounce 10 seconds or lost focus emited event
        this.pendingDebounce = true;
        return race(
          timer(DEBOUNCE).pipe(tap(()=>console.log(this.pendingRequest))), 
          this.$emitedLostFocus.pipe(debounceTime(DELAY))
        );
      }),
      tap(() => this.pendingDebounce = false),
      //debounceTime(DELAY),
      tap(() => solicitud.formulario = form.controls),
      tap(() => {
        this._deletePendingFiles();
        this._uploadPendingFiles();
      }),
      switchMap(data => this.partialSave(data))
    ).subscribe(() => {
    });
  }

  private _uploadPendingFiles(): void {
    const queueFilesToUpload = Array.from(this._formFileUploadSharedSrv.queueFilesToUpload.values());
    if (queueFilesToUpload.length) {
      queueFilesToUpload.forEach(fn => fn());
      this._formFileUploadSharedSrv.queueFilesToUpload.clear();
    }
  }

  private _deletePendingFiles(): void {
    const queueFilesToDelete = Array.from(this._formFileUploadSharedSrv.queueFilesToDelete.values());
    if (queueFilesToDelete.length) {
      queueFilesToDelete.forEach(fn => fn());
      this._formFileUploadSharedSrv.queueFilesToDelete.clear();
    }
  }

  /**
   * Función que retorna un observable con la data necesaria para su ejecución.
   * @param data La data que deberá tener el observable a retornar.
   * @param idContainer Id del contenedor (opcional). Se toma el contenedor actual por default.
   */
  private partialSave(data: any, idContainer?: string, fromManual?: boolean): Observable<any> {

    Object.assign(this._backup, data);

    if (!fromManual && this.denyPartialSave) {
      
      return of('');
    }

    if (this._avoidPartialSaves) {

      return this._storage.setItem(this._formData.getActualContainerId(), data);
    }
    
    if (this.denyPartialSave) {

      this._deletePendingFiles();
    }

    this.pendingRequest = true;

    // Before partialSave we need to remove the empty choicelist and choicelist group elements 
    // for not replace the value in hidden sections
    Object.keys(data).forEach(key => {

      const element = this._formData.getDatafrontElement(key);
      // Need to check if the section is hidden because the element into the section could be not hidden from implementation
      const secction = this._formData.getSectionOfElement(key);

      if (element && (element.elementType === FormElement.CHOICELIST_GROUP || element.elementType === FormElement.CHOICELIST)
        && secction && secction.isHidden && data[key].length === 0) {

        delete data[key];
      }
    });

    return this._origination
      .partialSave(this._globalSvc.getInstance().getValue().instanceId, idContainer || this._formData.getActualContainerId(), data)
      .pipe(
        tap(() => {

          this._formData.isSaving.next(false);
          this.pendingRequest = this.pendingDebounce;
        }),
        tap(resp => fromManual && resp['error'] && this._alert.error('Ocurrió un error en el guardado parcial.')),
        catchError(err => {

          this.pendingRequest = false;
          
          if (err instanceof HttpErrorResponse && err.status === 401) {
            
            this._avoidPartialSaves = false; //If error if about permisions the Agent can try again
            return throwError('El Agente no tiene los permisos necesarios para realizar la acción.');

          } else {

            this._avoidPartialSaves = true;
            this._storage.setItem(this._formData.getActualContainerId(), data).subscribe();

            if (!window.navigator.onLine) {
              this._alert.info('Tus datos estarán siendo guardados localmente hasta recuperar la conexión.');
              this.retryPartialSaving();
            }
          }

          return from([]);
        }),
        tap(() => this._formData.affectedFullNames.clear()));
  }

  /**
   * Función que se encarga guardar la información pendiente en el local storage una vez se detecte conexión a internet.
   */
  private retryPartialSaving(): void {
    interval(1000).pipe(
      takeWhile(() => !window.navigator.onLine)
    ).subscribe({
      complete: () => {
        this._avoidPartialSaves = false;
        this._alert.info('Se recuperó la conexión');
        this._storage.getItem(this._formData.getActualContainerId()).pipe(
          switchMap(saved => forkJoin([this.partialSave(saved), this.clearContainerLocalStorage()]))
        ).subscribe(() => {
          this._alert.success('Se ha recuperadó tu información localmente');
        });
      }
    });
  }

  /**
   * Función que procesa el formulario entero en base a los campos registrados como afectados.
   * @param form Diccionario conteniendo la información del formulario.
   */
  private transformForm(form: {}): any {
    const objCreated = {};
    Object.entries(form).forEach(e => {
      let fullNameRecord: any;
      let fullNameValue: any;
      const fullNameNamespace = e[0];
      
      fullNameRecord = this._formData.affectedFullNames.get(fullNameNamespace);

      // Need to check if the element has an errorText to force set errorText in the element 
      // because the errorText is not setted if the field is not changed
      const element = this._formData.getDatafrontElement(fullNameNamespace);

      if (!fullNameRecord && element && element.errorText !== '' && element.errorText !== null && element.errorText !== undefined) {

        const element = this._formData.getDatafrontElement(fullNameNamespace);
        const control = this._formData.getControl(fullNameNamespace);

        fullNameRecord = {
          element: element,
          control: control
        };
      }

      if (!fullNameRecord) {
        return;
      } else if (this.esElementoConDatatypeValido(fullNameRecord.control, fullNameRecord.element)) {
        fullNameValue = this.valueCorrectness(fullNameRecord.element, e[1]);
        if (fullNameNamespace.indexOf('.') === -1) {
          objCreated[fullNameNamespace.toString()] = fullNameValue;
        } else {
          this.transformFullName(objCreated, fullNameNamespace, fullNameValue);
        }
      }
    });
    return objCreated;
  }

  /**
   * Función que valida cada control del formulario contra el tipo de dato asociado, y en caso de un error, mostrarlo en el formulario.
   * @param form Formulario llave-valor.
   */
  public validateForm(form: FormGroup = this._formData.getContainerFormGroup(), isFormExit = false): boolean {
    Object.keys(form.controls).forEach(control => {
      const element = this._formData.getDatafrontElement(control);
      if (element) {
        const controlInstance = form.controls[control] as FormControl;
        if (isFormExit) {
          controlInstance.updateValueAndValidity();
        }
        this.esElementoConDatatypeValido(controlInstance, element);
      }
    });
    return form.valid;
  }

  private transformAllContainer(formContainer: FormGroup): any {
    const values = {};
    Object.keys(formContainer.controls).forEach(fullName => {
      if (FormIgnoredElements[fullName]) {
        return;
      } else if (formContainer.controls[fullName].invalid) return;
      const element = this._formData.getDatafrontElement(fullName);
      const rawValue = this.valueCorrectness(element, this._formData.getContainerFormGroup().controls[fullName].value);
      Object.assign(values, { [fullName.toString()]: rawValue });
    });
    return values;
  }

  private transFormFullName(element: ElementsForm, ignoreValidation = false): any {
    if (FormIgnoredElements[element.fullName]) return;
    const control = this._formData.getControl(element.fullName);
    if (!ignoreValidation && control.invalid) return;
    const values = {};
    const rawValue = this.valueCorrectness(element, control.value);
    Object.assign(values, { [element.fullName.toString()]: rawValue });
    return values;
  }

  /**
   * Función que descompone el "fullName" en un arreglo para su posterior tratamiento.
   * @param refObject Objeto donde se guardara el resultado.
   * @param fullNameNamespace El "fullName" a ser procesado.
   * @param fullNameValue El valor del "fullName" a guardar.
   */
  private transformFullName(refObject: any, fullNameNamespace: string, fullNameValue: any): void {
    const propsArr = fullNameNamespace.split('.');
    this.foundAndSave(Array.of(...propsArr), 0, refObject, fullNameValue);
    return refObject;
  }

  /**
   * Función que procesa el "fullName" descompuesto para generar la estructura correcta de envio al servidor.
   * @param props Arreglo de "strings" resultado de la descomposicion de un "fullName" en especifico.
   * @param index Indice de donde comenzará a leer en el arreglo de "strings".
   * @param obj Objeto contenedor donde se almacenará la información procesada.
   * @param value Valor a guardar en el objeto contenedor.
   */
  private foundAndSave(props: Array<string>, index: number, obj: {}, value: any): void {
    const prop = props[index];
    if (this.isPropertyAvailable(obj, prop)) {
      if (props.length === index) {
        obj[prop] = value;
      } else {
        this.foundAndSave(props, ++index, obj[prop.toString()], value);
      }
    } else if (props.length > index) {
      obj[prop.toString()] = {};
      if (++index > props.length - 1) {
        obj[prop.toString()] = value;
      } else {
        this.foundAndSave(props, index, obj[prop.toString()], value);
      }
    }
  }

  /**
   * Función que verifica si una propiedad existe en el objeto.
   * @param target Objeto
   * @param propName Nombre de la propiedad.
   */
  private isPropertyAvailable(target: {}, propName: string): boolean {
    const propFound = Object.getOwnPropertyNames(target);
    return propFound && !!propFound.find(p => p === propName);
  }

  /**
   * Función que transforma la data proveniente de los controles del formulario.
   * @param element Elemento de la definición.
   * @param value Valor actual del elemento en el formulario.
   */
  private valueCorrectness(element: ElementsForm, value: any): any {
    switch (element.elementType) {
      case FormElement.RADIO_GROUP:
      case FormElement.CHOICELIST: {
        if (!value) {
          value = [];
        } else if (!Array.isArray(value)) {
          value = [value];
        }
        break;
      }
      case FormElement.CHOICELIST_GROUP: {
        value = (value as []).filter(e => !!e);
        break;
      }
      case FormElement.DATE_PICKER_INLINE: {
        if (!!value) {
          value = moment(value, 'dd-MM-yyyy').format('DD/MM/YYYY');
        }
        break;
      }
      case FormElement.ATTACHMENT:
      case FormElement.IMAGE: {
        if (value && value.FileUrl && !value.FileUrl.includes('engine=')) {
          value.FileUrl += `${ value.FileUrl.includes('?') ? '&' : '?' }engine=${ Math.floor(Math.random() * 100000) }`;
        }
      }
    }
    if (Object.getOwnPropertyNames(element).find(prop => prop === 'textTransform') && value.toLowerCase) {
      value = !element.textTransform ? (value as string).toUpperCase() : (value as string).toLowerCase();
    }
    return value;
  }

  public emitLostFocus(debounce = 0){

    setTimeout(() => {
      
      this.$emitedLostFocus.next(0); 
    }, debounce);
  }
}

import { Injectable } from '@angular/core';
import { CatalogPermissions } from '../enums/catalog-permissions.enum';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BehaviorSubject } from 'rxjs';
import { CATALOGS } from '../enums/catalogs.enum';
import { ProfileService } from '../../shared/services/profile.service';
import { filter, take } from 'rxjs/operators';
import { MODULES } from '../enums/modules.enum';
import { FeatureFlagService } from 'src/app/shared/services/feature-flag.service';
import { FeatureFlagEnum } from 'src/app/shared/enums/featureFlag.enum';

// #region Constants

const PRODUCT_FORMS_CAPTION = 'Formularios de productos';

// #endregion

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private readonly LOCAL_STORAGE_KEY = 'permissions';
  private readonly toolTipText = 'No tienes permiso para realizar esta acción';

  // Permisos de catalogo principal
  CAN_CREATE = false;
  CAN_READ = false;
  CAN_UPDATE = false;
  CAN_DELETE = false;

  TOOLTIP_CREATE: string;
  TOOLTIP_READ: string;
  TOOLTIP_UPDATE: string;
  TOOLTIP_DELETE: string;

  // Permisos de catalogo externo al que se quiere accesar si es que se tiene.
  CAN_CREATE_EXTERNAL = false;
  CAN_READ_EXTERNAL = false;
  CAN_UPDATE_EXTERNAL = false;
  CAN_DELETE_EXTERNAL = false;

  TOOLTIP_CREATE_EXTERNAL: string;
  TOOLTIP_READ_EXTERNAL: string;
  TOOLTIP_UPDATE_EXTERNAL: string;
  TOOLTIP_DELETE_EXTERNAL: string;

  catalogPermissions = CatalogPermissions;
  permissions: any;
  idsPermissionsToIgnore = [CATALOGS.CREATE_INSTANCE, CATALOGS.REASSIGNATION, CATALOGS.DATA_EXPORT];
  urlsAllowed: Array<string> = [];

  permissionsReady = new BehaviorSubject<boolean>(false);

  constructor(private readonly translate: TranslateService, private readonly _localStorage: LocalStorage,
              private readonly _profile: ProfileService, private readonly featureFlagService: FeatureFlagService) {

    this.recoverPermissions();
    this._profile.userProfile$.pipe(filter(info => !!info), take(1)).subscribe(user => {

      this.permissions = user.permissions.permissions;

      // Add a custom permission for the forms of the products
      // this is a solution to handle the permissions of the forms of the products as child permissions of the products 
      // this action take place only if feature flag is enabled
      const adminFormsFlag: boolean = this.featureFlagService.getFlagValue(FeatureFlagEnum.PRODUCTS_FORMS_ADMIN);

      if (adminFormsFlag) {

        for(const module of this.permissions) {

          if (module.id === MODULES.PRODUCTS) {
  
            for (const catalog of module.catalogs) {
              
              if (catalog.id === CATALOGS.PRODUCTS) {
  
                module.catalogs.push({
                  id: CATALOGS.PRODUCTS_FORMS,
                  catalogName: PRODUCT_FORMS_CAPTION,
                  actions: catalog.actions
                });
              }
            }
          }
        }
      }
      
      this.permissionsReady.next(true);
    });
  }

  public setPermissions(idModule: string, idCatalog: string, idExternalModule?: string, idExternalCatalog?: string): void {
    const catalogFound = this.findCatalog(idModule, idCatalog);
    let externalCatalogFound;
    if (idExternalModule && idExternalCatalog) {
      externalCatalogFound = this.findCatalog(idExternalModule, idExternalCatalog);
    }
    if (catalogFound) this.initializeFlags(catalogFound, externalCatalogFound);
    this.savePermissions();
  }

  findCatalog(idModule: string, idCatalog: string) {
    const moduleFound = this.permissions.find(m => m.id === idModule);
    if (moduleFound) {
      return moduleFound.catalogs.find(c => c.id === idCatalog);
    }
  }

  canReadCatalog(idModule: string, idCatalog: string): boolean {
    const catalogFound = this.findCatalog(idModule, idCatalog);
    return !!catalogFound['actions'].find((a: any) => a === this.catalogPermissions.READ);
  }

  savePermissions(): void {
    this._localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.permissions)).subscribe();
  }

  recoverPermissions(): void {
    this._localStorage.getItem(this.LOCAL_STORAGE_KEY).subscribe(data => {
      if (data) {
        this.permissions = JSON.parse(data);
        this._localStorage.removeItem(this.LOCAL_STORAGE_KEY).subscribe();
      }
    });
  }

  private initializeFlags(catalog: any, externalCatalog?: any): void {
    this.CAN_CREATE = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.CREATE);
    this.CAN_READ = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.READ);
    this.CAN_UPDATE = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.UPDATE);
    this.CAN_DELETE = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.DELETE);

    if (externalCatalog) {
      this.CAN_CREATE_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.CREATE);
      this.CAN_READ_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.READ);
      this.CAN_UPDATE_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.UPDATE);
      this.CAN_DELETE_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.DELETE);
    }

    this.getToolTips(externalCatalog);
  }

  private getToolTips(externalCatalog?: any): void {
    this.TOOLTIP_CREATE = this.CAN_CREATE ? '' : this.toolTipText;
    this.TOOLTIP_READ = this.CAN_READ ? '' : this.toolTipText;
    this.TOOLTIP_UPDATE = this.CAN_UPDATE ? '' : this.toolTipText;
    this.TOOLTIP_DELETE = this.CAN_DELETE ? '' : this.toolTipText;

    if (externalCatalog) {
      this.TOOLTIP_CREATE_EXTERNAL = this.CAN_CREATE_EXTERNAL ? '' : this.toolTipText;
      this.TOOLTIP_READ_EXTERNAL = this.CAN_READ_EXTERNAL ? '' : this.toolTipText;
      this.TOOLTIP_UPDATE_EXTERNAL = this.CAN_UPDATE_EXTERNAL ? '' : this.toolTipText;
      this.TOOLTIP_DELETE_EXTERNAL = this.CAN_DELETE_EXTERNAL ? '' : this.toolTipText;
    }

  }

  /// function to get the permissions of the user in reduced javascript object
  public getReducedPermisions() {

    // Default permissions
    const result = {
      products: {
        create: this.CAN_CREATE,
        read: this.CAN_READ,
        update: this.CAN_UPDATE,
        delete: this.CAN_DELETE
      },
    };

    for (const module of this.permissions) {

      for (const catalog of module.catalogs) {

        // handle the permissions related to admin products operations
        if(catalog.id === CATALOGS.PRODUCTS) {

          result.products = {
            create: catalog.actions.includes(this.catalogPermissions.CREATE),
            read: catalog.actions.includes(this.catalogPermissions.READ),
            update: catalog.actions.includes(this.catalogPermissions.UPDATE),
            delete: catalog.actions.includes(this.catalogPermissions.DELETE)
          }; 
        }
      }
    }

    return result;
  }
}

import { ADMINISTRATION } from '../../configs/urls.config';

export enum CATALOGS {
  // Catalogos usados con ids de back.
  AGENTS = '8406de43-1a0d-4335-bca1-3428da5ff729',
  CUSTOM = '607ba266-1e9b-4ae6-9026-dec1c1f16764',
  ROLES = '6f7a7d05-9b46-4291-a54e-03323a035dde',
  VARIABLES_GROUP = 'ba51b152-4a67-442e-9e8a-4434feb285ff',
  HIERARCHY = 'baab783f-f97d-4163-af2f-50b6c919d050',
  DATA_TYPES = 'f62d97c4-0b11-49fb-a093-fd9839a43049',
  DATA_EXPORT = 'c74910da-78a7-42f2-8a8d-20bfc42ba8b7',
  PRODUCTS = 'ac3dbb5f-d4b9-4095-8400-5b439c957ec6',
  POLYGONS = 'f4480205-2d12-4676-9c33-147779c1e883',
  BULK_LOAD = '7f193b78-308b-47b2-86c0-5bcc81b75382',
  CREATE_INSTANCE = '464c98e1-ebee-4819-8326-a32595d34274',
  REASSIGNATION = '8af4e9a5-95ed-4003-9fcb-7e000ae6d7b6',

  // Catalogos sin usar
  VARIABLES = 'da098ae7-d629-4a5a-944f-fb2c0c4a4959',

  // New page to admin products forms
  PRODUCTS_FORMS = 'ac3dbb5f-d4b9-4095-8400-5b439c957ec0',
}

export enum URLS_ENUM {
  '8406de43-1a0d-4335-bca1-3428da5ff729' = ADMINISTRATION.AGENTS,
  '607ba266-1e9b-4ae6-9026-dec1c1f16764' = ADMINISTRATION.CUSTOM_CATALOGS,
  '6f7a7d05-9b46-4291-a54e-03323a035dde' = ADMINISTRATION.ROLES,
  'ba51b152-4a67-442e-9e8a-4434feb285ff' = ADMINISTRATION.VARIABLE_GROUP,
  'baab783f-f97d-4163-af2f-50b6c919d050' = ADMINISTRATION.HIERARCHY,
  'f62d97c4-0b11-49fb-a093-fd9839a43049' = ADMINISTRATION.DATA,
  'c74910da-78a7-42f2-8a8d-20bfc42ba8b7' = ADMINISTRATION.DATA_EXPORT,
  'ac3dbb5f-d4b9-4095-8400-5b439c957ec6' = ADMINISTRATION.PRODUCTS,
  'f4480205-2d12-4676-9c33-147779c1e883' = ADMINISTRATION.POLYGINS_CATALOGS,
  '7f193b78-308b-47b2-86c0-5bcc81b75382' = ADMINISTRATION.BULK_LOAD,
  'ac3dbb5f-d4b9-4095-8400-5b439c957ec0' = ADMINISTRATION.PRODUCTS_FORMS,
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileType } from 'src/app/shared/enums/file-type.enum';
import { FormContext, FormElement } from 'src/app/shared/enums';
import { FormUploadService } from 'src/app/shared/services/form-upload.service';
import { MatDialog } from '@angular/material';
import { Upload } from 'src/app/shared/models/upload';
import { ImageLoaderFullscreenComponent } from '../../../../shared/components/image-loader/image-loader-fullscreen/image-loader-fullscreen.component';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { IUploadService } from 'src/app/shared/bases/services/IUploadService';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertService } from '../../../../shared/services/alerts.service';
import { FunctionsService } from '../../../../shared/services/functions.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { FormUtilitiesService } from 'src/app/shared/services/form-utilities.service';
import { ProfileService } from '../../../../shared/services/profile.service';
import { FormFileUploaderSharedService } from '../../../../shared/services/form-file-uploader-shared.service';
import { FilePreviewAction } from 'src/app/shared/enums/file-preview-action.enum';

const MIME_TYPES_ALLOWED = ['application/pdf', 'text/csv', 'application/vnd.ms-excel', 'text/plain', 'image/jpeg', 'image/jpg', 'image/png',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

@Component({
  selector: 'scc-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss']
})
export class CustomFileUploadComponent implements OnInit {
  @Input() upload: Upload;
  @Input() disabled: boolean;
  @Input() fileService: IUploadService;
  @Input() fullName: string;
  // @Input() globalSrv: GlobalService;

  @Output() imageUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileProgress: EventEmitter<number> = new EventEmitter<number>();
  @Output() fileInfo: EventEmitter<Upload> = new EventEmitter<Upload>();

  @Input() selectedInstance: any;
  @Input() selectedContainer: any;
  dropzoneActive = false;
  formElementEnum = FormElement;
  currentFormContext: FormContext;
  uploadList: Upload[] = [];
  denyPartialSave = true;

  constructor(private readonly formUploadService: FormUploadService, private readonly alertService: AlertService,
              private readonly functionService: FunctionsService, private readonly formUtilitiesService: FormUtilitiesService,
              public dialog: MatDialog, private readonly loaderService: LoaderService, private readonly _profile: ProfileService,
              private readonly _formFileUploadSharedSrv: FormFileUploaderSharedService) {
    this._profile.userProfile$.asObservable().pipe(filter(userInfo => !!userInfo)).subscribe(userInfo => {
      this.denyPartialSave = userInfo.originations.denyPartialSave;
    });
  }

  ngOnInit() {
    if (this.upload && this.upload.blobUrl) {
      this.getFileFromBlobUrl(this.upload.blobUrl, this.upload.mimeType);
    }

    this.formUtilitiesService.contextChanged$.pipe(distinctUntilChanged()).subscribe(context => {
      this.currentFormContext = context;
    });
  }

  getFileFromBlobUrl(blobUrl: string, mimeType) {
    this.upload.fileType = this.validaTipo(this.upload.mimeType, this.upload);
    if (mimeType) {
      this.formUploadService.getBase64(blobUrl).subscribe(response => {
        this.getContent(response, mimeType);
      });
    } else {
      this.formUploadService.getBlob(blobUrl).subscribe(response => {
        const file = new File([response], this.upload.name, { type: response.type });
        this.upload.mimeType = response.type;
        this.upload.name = file.name;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
          this.upload.content = e.target.result;
          this.imageLoaded.emit(this.upload);
        };
      });
    }
  }

  getContent(arrayBuffer: ArrayBuffer, mimeType: string) {
    const base64String = btoa(
      new Uint8Array(arrayBuffer)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    this.upload.base64 = base64String;
    this.upload.content = 'data:' + mimeType + ';base64,' + base64String;
    this.setSizes(this.upload.content);
  }

  setSizes(base64: string) {
    const img = new Image();
    img.src = base64;
    img.addEventListener('load', () => {
      this.upload.width = img.width;
      this.upload.height = img.height;
    });
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDropInput(file, upload) {
    this.handleDrop(file.target.files, upload, file.target);
  }

  handleDropInputMultiple(file, upload) {
    this.handleDropMultiple(file.target.files, upload);
  }

  validaTipo(actualFileType: string, uploadFile: Upload): FileType {
    let filetype;
    if (uploadFile.formElement === this.formElementEnum.IMAGE) {
      if (actualFileType.substr(0, 6) === 'image/') {
        filetype = FileType.IMAGE;
      } else {
        filetype = FileType.ERROR;
      }
    } else if (actualFileType && this.upload.validFileFormats && uploadFile.formElement === this.formElementEnum.ATTACHMENT
      && MIME_TYPES_ALLOWED.includes(actualFileType)) {
      if (/application\//.test(actualFileType)) {
        if (actualFileType === 'application/pdf') {
          filetype = FileType.PDF;
        } else if (/application\/vnd./.test(actualFileType)) {
          filetype = FileType.FILE;
        } else {
          filetype = FileType.ERROR;
        }
      } else if (actualFileType.substr(0, 6) === 'image/') {
        if (/jpg|jpeg|png/.test(actualFileType)) {
          filetype = FileType.IMAGE;
        } else {
          filetype = FileType.ERROR;
        }
      } else {
        filetype = FileType.FILE;
      }
    } else {
      filetype = FileType.ERROR;
    }
    return filetype;
  }

  validaExtension(name: string, validFileFormats: string): FileType {
    if (validFileFormats.split(',').find(ext => name.indexOf(ext) >= 0)) return FileType.FILE;
  }

  handleDrop(fileList: FileList, upload, target?) {
    const file = fileList[0] as any;

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      this.upload = upload;
      this.upload.fileType = this.validaTipo(file['type'], this.upload);
      if (this.upload.fileType === FileType.ERROR) {
        this.alertService.error('Archivo con extensión inválida');
        if (target) target.value = '';
        return;
      }

      if (((file['size'] / 1024) / 1024) > this.upload.fileSize) {
        this.alertService.error('El tamaño del archivo excede el límite máximo');
        if (target) target.value = '';
        return;
      }

      const loader = this.loaderService.activeLoaders.get(this.fullName);
      if (this.fullName && loader) loader.next(true);

      this.upload.file = file;
      this.fileInfo.emit(this.upload);

      reader.readAsDataURL(file);
      this.functionService.forceLoader(true);
      reader.onload = (e: any) => {
        const readFile = e.target.result;
        this.upload.name = file.name;
        this.upload.mimeType = file['type'];
        this.setSizes(readFile);
        this.upload.base64 = readFile.split(',')[1];

        if (!this.fileService) this.fileService = this.formUploadService;
        if (this._formFileUploadSharedSrv.inEngine && !this._formFileUploadSharedSrv.inFormEdit && this.denyPartialSave
          && !this._formFileUploadSharedSrv.ignorePartialSavePermission) {
          loader.next(false);
          this.upload.content = readFile;
          if (this._formFileUploadSharedSrv.queueFilesToDelete.has(this.upload.fullname)) {
            this._formFileUploadSharedSrv.queueFilesToDelete.delete(this.upload.fullname);
          }
          this.imageUploaded.emit(this.upload);
          this._formFileUploadSharedSrv.queueFilesToUpload.set(this.upload.fullname, () => {
            loader.next(true);
            this.uploadFile(readFile, loader);
          });
        } else {
          this.uploadFile(readFile, loader);
        }
      };
    }
  }

  private uploadFile(readFile: any, loader: any): void {   
    this.fileService.push(this.upload, { selectedInstance: this.selectedInstance, selectedContainer: this.selectedContainer })
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
          case HttpEventType.ResponseHeader:
          case HttpEventType.DownloadProgress:
            break;
          case HttpEventType.UploadProgress:
            this.fileProgress.emit(event.loaded);
            break;
          case HttpEventType.Response:
            const response = (event.body);
            if (response.status === 0) {
              this.upload.blobUrl = response.url;
              this.upload.content = readFile;
              if (this.denyPartialSave) {
                this.upload.blobUrl += `&engine=${ Math.floor(Math.random() * 100000) }`;
              }
              this.imageUploaded.emit(this.upload);
            } else {
              this.alertService.error('Ocurrió un error al guardar el archivo');
            }
            if (this.fullName && loader) loader.next(false);
            break;
          default:
            console.error('Error en la carga', event);
            if (this.fullName && loader) loader.next(false);
            break;
        }
      });
  }

  handleDropMultiple(fileList: FileList, upload) {
    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      const tmpUpload = { ...upload } as Upload;
      tmpUpload.name = file.name;
      tmpUpload.mimeType = file['type'];
      tmpUpload.file = file;
      tmpUpload.cancel = new Subject<void>();
      this.uploadList.push(tmpUpload);

      tmpUpload.fileType = this.validaTipo(file['type'], tmpUpload);
      if (tmpUpload.fileType === FileType.ERROR) tmpUpload.fileType = this.validaExtension(file.name, tmpUpload.validFileFormats);
      if (tmpUpload.fileType === FileType.ERROR) {
        tmpUpload.error = 'Archivo con extensión inválida';
        tmpUpload.status = -1;
        // if (target) target.value = '';
      } else if (((file['size'] / 1024) / 1024) > tmpUpload.fileSize) {
        tmpUpload.error = `El tamaño del archivo excede el límite máximo`;
        tmpUpload.status = -1;
        // if (target) target.value = '';
      } else {
        this.fileService.push(tmpUpload)
          .pipe(takeUntil(tmpUpload.cancel))
          .subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
              case HttpEventType.ResponseHeader:
              case HttpEventType.DownloadProgress:
                break;
              case HttpEventType.UploadProgress:
                tmpUpload.loaded = event.loaded;
                break;
              case HttpEventType.Response:
                const response = (event.body);
                tmpUpload.status = response.status;
                if (response.status !== 0) tmpUpload.error = 'Ocurrió un error al guardar el archivo';
                break;
              default:
                tmpUpload.status = -1;
                tmpUpload.error = 'Error en la carga';
                break;
            }
            this.functionService.forceLoader(false);
          });
      }
    }
  }

  download() {
    const url = this.getUrl();
    let link: any = document.getElementById('tmpA');
    if (!link) {
      link = document.createElement('a');
    }
    link.id = 'tmpA';
    link.href = url;
    link.download = this.upload.name;
    link.click();
  }

  print() {
    let iframe: any = document.getElementById('tmpIframe');
    if (!iframe) {
      iframe = document.createElement('iframe');
    }
    iframe.id = 'tmpIframe';
    document.body.appendChild(iframe);

    iframe.src = this.getUrl();
    iframe.style.display = 'none';
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  getUrl(): string {
    const blobData = this.functionService.base64toBlob(this.upload.base64, { type: this.upload.mimeType });
    const blob = new Blob([blobData], { type: this.upload.mimeType });
    return window.URL.createObjectURL(blob);
  }

  verifyDelete(): void {
    if (this.denyPartialSave && !this._formFileUploadSharedSrv.ignorePartialSavePermission) {
      if (!this._formFileUploadSharedSrv.queueFilesToUpload.size) {
        this._formFileUploadSharedSrv.queueFilesToDelete.set(this.upload.fullname, this.deleteWithQueue.bind(this));
      } else {
        this._formFileUploadSharedSrv.queueFilesToUpload.clear();
      }
      this.imageDeleted.emit(true);
      return;
    }
    this.delete();
  }

  deleteWithQueue() {
    const loader = this.loaderService.activeLoaders.get(this.fullName);
    if (this.currentFormContext !== FormContext.FORM_EDIT_FORM) {
      if (this.fullName && loader) loader.next(true);
      this.formUploadService.deleteFile(this.upload.fullname, this.selectedInstance, this.selectedContainer).subscribe(response => {
        if (response.status === 0) {
          this.upload = new Upload();
          if (this.fullName && loader) loader.next(false);
        } else {
          this.alertService.error('Ocurrió un error al borrar el archivo');
          if (this.fullName && loader) loader.next(false);
        }
      });
    } else {
      this.upload = new Upload();
      if (this.fullName && loader) loader.next(false);
    }
  }

  delete() {
    const loader = this.loaderService.activeLoaders.get(this.fullName);
    if (this.currentFormContext !== FormContext.FORM_EDIT_FORM) {
      if (this.fullName && loader) loader.next(true);
      this.formUploadService.deleteFile(this.upload.fullname, this.selectedInstance, this.selectedContainer).subscribe(response => {
        if (response.status === 0) {
          this.imageDeleted.emit(true);
          this.upload = new Upload();
          if (this.fullName && loader) loader.next(false);
        } else {
          this.alertService.error('Ocurrió un error al borrar el archivo');
          if (this.fullName && loader) loader.next(false);
        }
      });
    } else {
      this.imageDeleted.emit(true);
      this.upload = new Upload();
      if (this.fullName && loader) loader.next(false);
    }
  }

  openFullScreen(upload, esPrecargada) {
    upload.esPrecargada = esPrecargada;
    const dialogRef = this.dialog.open(ImageLoaderFullscreenComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '100%',
      height: '100%',
      data: upload,
      panelClass: 'custome-dialog-panel'
    });

    dialogRef.afterClosed()
    .pipe(filter(result => result && result.data))
    .subscribe(result => {
      
      switch (result.action) {
        
        case FilePreviewAction.DELETE:

          this.delete();
          
          break;

        case FilePreviewAction.SAVE:

          const loader = this.loaderService.activeLoaders.get(this.fullName);

          if (!this.fileService) {

            this.fileService = this.formUploadService;
          }

          this.upload.base64 = result.data;
          const base64View = `data:${this.upload.mimeType};base64,${result.data}`;
          this.uploadFile(base64View, loader);
          this.setSizes(base64View);
          break;
      }
    });
  }
}

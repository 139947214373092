export enum ADMINISTRATION {
  START = '/administration',
  ADVANCED_SEARCH = '/administration/advanced-search',
  SEARCH = '/administration/search',
  FAST_SEARCH = '/administration/fast-search',
  AGENTS = '/administration/agents',
  AGENTS_DETAIL = '/administration/agents/detail',
  PRODUCTS = '/administration/products',
  PRODUCTS_FORMS = '/administration/products/forms-designer',
  PRODUCTS_EDIT = '/administration/products/edit',
  DATAFRONT_DESIGNER = '/administration/products/data-designer',
  PRODUCTS_NEW = '/administration/products/new',
  DATA = '/administration/data',
  DATA_EXPORT = '/administration/instances/result',
  DATA_COMPOUSED = '/administration/data?dataType=compuesto',
  DATA_COMPOUND = '/administration/data/compound',
  DATA_PRODUCT = '/administration/data/product',
  DESIGNER_COMPOUND = '/administration/data/designer/compound',
  DESIGNER_PRODUCT = '/administration/products/designer',
  DATA_DESIGNER = '/administration/data/designer',
  ROLES = '/administration/agents/roles',
  DETAIL_ROL = '/administration/agents/roles/detail',
  ADD_ROL = '/administration/agents/roles/add',
  VARIABLE_GROUP = '/administration/agents/variable-group',
  DETAIL_VARIABLE_GROUP = '/administration/agents/variable-group/detail',
  ADD_VARIABLE_GROUP = '/administration/agents/variable-group/add',
  ADD_VARIABLE = '/administration/agents/variables',
  ADD_VARIABLE_TO_GROUP = '/administration/agents/variable/add',
  DETAIL_VARIABLE = '/administration/agents/variable/detail',
  CUSTOM_CATALOGS = '/administration/agents/custom-catalogs',
  ADD_CUSTOM_CATALOGS = '/administration/agents/custom-catalogs/add',
  CUSTOM_CATALOGS_LIST = '/administration/agents/custom-catalogs/custom-catalogs-list',
  POLYGINS_CATALOGS = '/administration/catalogs/polygon-catalog',
  ADD_POLYGON = '/administration/catalogs/polygon-catalog/add',
  DETAIL_POLYGON = '/administration/catalogs/polygon-catalog/detail',
  HIERARCHY = '/administration/agents/folders',
  HIERARCHY_DETAIL = '/administration/agents/folders/detail',
  HIERARCHY_INBOX_DETAIL = '/administration/agents/inbox/detail',
  PROFILE = 'Agents/profile',
  BULK_LOAD = '/administration/bulkload'
}

export const URLS_SETTINGS = {
  DASHBOARD: '/',
  ADMINISTRATION: {
    START: ADMINISTRATION.START,
    ADVANCED_SEARCH: ADMINISTRATION.ADVANCED_SEARCH,
    SEARCH: ADMINISTRATION.SEARCH,
    FAST_SEARCH: ADMINISTRATION.FAST_SEARCH,
    AGENTS: ADMINISTRATION.AGENTS,
    AGENTS_DETAIL: ADMINISTRATION.AGENTS_DETAIL,
    PRODUCTS: ADMINISTRATION.PRODUCTS,
    PRODUCTS_EDIT: ADMINISTRATION.PRODUCTS_EDIT,
    DATAFRONT_DESIGNER: ADMINISTRATION.DATAFRONT_DESIGNER,
    PRODUCTS_NEW: ADMINISTRATION.PRODUCTS_NEW,
    DATA: ADMINISTRATION.DATA,
    DATA_COMPOUSED: ADMINISTRATION.DATA_COMPOUSED,
    DATA_COMPOUND: ADMINISTRATION.DATA_COMPOUND,
    DATA_PRODUCT: ADMINISTRATION.DATA_PRODUCT,
    DESIGNER_COMPOUND: ADMINISTRATION.DESIGNER_COMPOUND,
    DESIGNER_PRODUCT: ADMINISTRATION.DESIGNER_PRODUCT,
    DATA_DESIGNER: ADMINISTRATION.DATA_DESIGNER,
    ROLES: ADMINISTRATION.ROLES,
    DETAIL_ROL: ADMINISTRATION.DETAIL_ROL,
    ADD_ROL: ADMINISTRATION.ADD_ROL,
    VARIABLE_GROUP: ADMINISTRATION.VARIABLE_GROUP,
    DETAIL_VARIABLE_GROUP: ADMINISTRATION.DETAIL_VARIABLE_GROUP,
    ADD_VARIABLE_GROUP: ADMINISTRATION.ADD_VARIABLE_GROUP,
    ADD_VARIABLE: ADMINISTRATION.ADD_VARIABLE,
    ADD_VARIABLE_TO_GROUP: ADMINISTRATION.ADD_VARIABLE_TO_GROUP,
    DETAIL_VARIABLE: ADMINISTRATION.DETAIL_VARIABLE,
    CUSTOM_CATALOGS: ADMINISTRATION.CUSTOM_CATALOGS,
    ADD_CUSTOM_CATALOGS: ADMINISTRATION.ADD_CUSTOM_CATALOGS,
    CUSTOM_CATALOGS_LIST: ADMINISTRATION.CUSTOM_CATALOGS_LIST,
    POLYGINS_CATALOGS: ADMINISTRATION.POLYGINS_CATALOGS,
    ADD_POLYGON: ADMINISTRATION.ADD_POLYGON,
    DETAIL_POLYGON: ADMINISTRATION.DETAIL_POLYGON,
    HIERARCHY: ADMINISTRATION.HIERARCHY,
    HIERARCHY_DETAIL: ADMINISTRATION.HIERARCHY_DETAIL,
    HIERARCHY_INBOX_DETAIL: ADMINISTRATION.HIERARCHY_INBOX_DETAIL,
    PROFILE: ADMINISTRATION.PROFILE,
    BULK_LOAD: ADMINISTRATION.BULK_LOAD
  }
};



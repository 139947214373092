import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ElementsForm, ReferenceChange, RowForm, SectionForm, SubRequestForm } from 'src/app/shared/models/form-structure';
import { FormContext, FormElement, FormElementSize } from 'src/app/shared/enums';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { FunctionsService } from '../../../../shared/services/functions.service';
import { FormUtilitiesService } from '../../../../shared/services/form-utilities.service';
import { FormDataService } from '../../../../shared/services/form-data.service';
import { InstanceService } from '../../../../shared/services/instance.service';
import { FormService } from '../../../../shared/services/form.service';
import { GlobalService } from '../../../../shared/services/global.service';
import { takeUntil } from 'rxjs/operators';
import { DtoDatafrontSectionRow } from '../../../../shared/models/Datafront/DtoDatafrontSectionRow';
import { asap } from 'rxjs/internal/scheduler/asap';

// #region Constants

const SECTION_SHOW_KEY = 'show';

// #endregion

@Component({
  selector: 'scc-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss']
})
export class FormSectionComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewInit {
  @Input() isFormEdit = false;
  @Output() loadingSubSection: EventEmitter<SubRequestForm> = new EventEmitter<SubRequestForm>();
  contextChangedSubscription: any;
  @Output() isHiddenSubform: EventEmitter<boolean> = new EventEmitter();
  private sectionForm: SectionForm;
  private sectionFormResp: SectionForm;
  private sectionRowsResp: DtoDatafrontSectionRow[];
  private updateSubSectionEvent: Subscription;
  private _stopSubscription = false;
  private _stopSubscriptions$ = new Subject<boolean>();

  get section(): SectionForm {
    return this.sectionForm;
  }

  @Input()
  set section(section: SectionForm) {
    if (this.isFormEdit) {
      section.formEditFullname = this.formDataService.activeFormEdit;
    }
    this.sectionForm = section;
    this.sectionFormResp = JSON.parse(JSON.stringify(section));
  }

  constructor(private readonly functionsService: FunctionsService,
              private readonly formUtilitiesService: FormUtilitiesService,
              public readonly formDataService: FormDataService,
              private readonly instanceService: InstanceService,
              private readonly formService: FormService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly _global: GlobalService) {
    this.updateSubSectionEvent = functionsService.updateSubSectionEvent.subscribe(
      (result: ReferenceChange) => {
        if (this.section && this.section.rows && this.section.rows.length > 0) {
          for (const row of this.section.rows) {
            if (
              row.subRequestForm &&
              row.subRequestForm.enableRule &&
              row.subRequestForm.enableRule.fieldReferences.includes(
                result.fullName
              )
            ) {
              const calculatedValueFieldReferences: string[] =
                row.subRequestForm.enableRule.fieldReferences;
              const calculatedValueFieldValues: any = {};

              this.formService.deleteFormFields(row.subRequestForm);

              for (
                let index = 0;
                index < calculatedValueFieldReferences.length;
                index++
              ) {
                let referenceValue: any;
                const currentReference = calculatedValueFieldReferences[index];
                if (result.fullName === currentReference) {
                  referenceValue = result.value;
                } else {
                  referenceValue = this.instanceService.getValue(
                    currentReference
                  );
                }
                calculatedValueFieldValues[currentReference] = referenceValue;
              }

              let isAffirmative = null;
              try {
                isAffirmative = this.functionsService.evalFunction(
                  this.functionsService.b64DecodeUnicode(
                    row.subRequestForm.enableRule.fieldFunction
                  ),
                  calculatedValueFieldValues
                );
              } catch (error) {
                console.error(
                  'FUNCTION:',
                  this.functionsService.b64DecodeUnicode(
                    row.subRequestForm.enableRule.fieldFunction
                  ),
                  'VALUES:',
                  JSON.stringify(calculatedValueFieldValues)
                );
              }
              row.subRequestForm.isHidden = !isAffirmative;
              if (!row.subRequestForm.isHidden) {
                this.formService.addFormFields(row.subRequestForm);
              }
            }
          }
        }
      }
    );
  }

  ngOnInit() {
    if (this.section.enableRule && this.section.enableRule.fieldFunction && this.section.enableRule.fieldReferences &&
      this.section.enableRule.fieldReferences.length) {
      this.formDataService.addSectionsGroup(this.section);
      const functionToUse = this.functionsService.b64DecodeUnicode(this.sectionForm.enableRule.fieldFunction);
      const references = {};
      this.sectionForm.enableRule.fieldReferences.forEach(fieldReference => {
        if (this.formDataService.activeFormEdit) {
          const form = this.formDataService.getFormEditFormGroup(this.formDataService.activeFormEdit);
          form.controls[fieldReference].valueChanges
            .pipe(takeUntil(this._stopSubscriptions$))
            .subscribe(value => {
              references[fieldReference] = value;
              const fn = new Function(functionToUse);
              const res = fn.call(references);
              if (res) this.section.isHidden = !res['show'];
            });
        } else {
          let formEditTable: BehaviorSubject<[]>, toSubscribe: Observable<any>;
          const formControl = this.formDataService.getContainerFormGroup().controls[fieldReference];
          if (!formControl) {
            formEditTable = this.formDataService.getFormEditEmitterByFullName(fieldReference);
          }

          if (formControl) {
            toSubscribe = formControl.valueChanges;
          } else if (formEditTable) {
            toSubscribe = formEditTable;
          }

          asap.schedule(() => {

            const getFnResult = (value: any) => {

              references[fieldReference] = value;
              const fn = new Function(functionToUse);
              const res = fn.call(references);

              if (res && res.hasOwnProperty(SECTION_SHOW_KEY)) {

                this.section.isHidden = !res[SECTION_SHOW_KEY];
              }
            };

            toSubscribe.pipe(takeUntil(this._stopSubscriptions$))
              .subscribe(value => {
                getFnResult(value);
              });

            if (!this.formDataService.isPollingForm && formControl) {

              getFnResult(formControl.value);
            }
          });
        }
      });
    }
    this.sectionRowsResp = JSON.parse(JSON.stringify(this.sectionForm.rows));
    if (this.isFormEdit) {
      this.section.rows.forEach(row => {
        (row.elements as ElementsForm[]).forEach((element: ElementsForm) => {
          const copy = JSON.parse(JSON.stringify(element)) as ElementsForm;
          if (copy.elementType === FormElement.CHOICELIST_GROUP) {
            copy.fullName = `${ copy.fullName }${ copy.selectionIndex }`;
          } else if (copy.elementType === FormElement.PARTIAL_FUNCTION) {
            this.formDataService.addPartialFunction(element.fullName, new BehaviorSubject<boolean>(false));
          }
          this.formDataService.addDatafrontElement(element);
          this.formDataService.addDatafrontElementHelper(copy);
          if (copy.elementType === FormElement.FORM_EDIT) this.formDataService.addFormEditFormGroup(copy.fullName);
        });
      });
    }
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
    this.contextChangedSubscription = this.formUtilitiesService.contextChanged$.pipe(takeUntil(this._stopSubscriptions$)).subscribe(
      context => {
        if (context === FormContext.SECTION_FORM) {
          this.loadSectionsValidations();
        }
      }
    );
    if (this.isFormEdit) {
      const form = this.formDataService.getFormEditFormGroup(this.formDataService.activeFormEdit);
      if (form) {
        if (!this.formDataService.formEditStatus) {
          form.disable();
          this.formDataService.formEditStatus = false;
        }
      }
      this.formDataService.isFormEditSectionLoaded$.next();
    }
  }

  ngOnDestroy(): void {
    this.contextChangedSubscription.unsubscribe();
    this.updateSubSectionEvent.unsubscribe();
    this._stopSubscription = true;
    this.section.isHidden = this.sectionFormResp.isHidden;
    this.section.rows = this.sectionRowsResp;
    this._stopSubscriptions$.next(true);
  }

  elementSize(row: RowForm, isHidden: boolean) {
    let grid = '';
    switch (row.elementSize) {
      case FormElementSize.COL_1:
        grid = 'col-xs-12 in-capture col-sm-12';
        break;

      case FormElementSize.COL_2:
        grid = 'col-xs-12 in-capture col-sm-12 col-md-6';
        break;

      case FormElementSize.COL_3:
        grid = 'col-xs-12 in-capture col-sm-12 col-md-4';
        break;

      case FormElementSize.COL_4:
        grid = 'col-xs-12 in-capture col-sm-12 col-md-3';
        break;
    }
    grid = isHidden ? grid + ' hiddenElement' : grid;
    return grid;
  }

  onHidden(event, elem) {
    let elementFound;
    if (elem.elementType === FormElement.CHOICELIST_GROUP) {
      elementFound = this.formDataService.getDatafrontElementHelper(elem.fullName, elem.elementType, elem.selectionIndex);
      elementFound.isHidden = event;
    }
    elem.isHidden = event;
  }

  private loadSectionsValidations() {
    for (let i = 0; i < this.section.rows.length; i++) {
      const row = this.section.rows[i];
      if (row.subRequestForm && !row.subRequestForm.isHidden) {
        this.formService.addFormFields(row.subRequestForm);
      }
    }
  }
}
